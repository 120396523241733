import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Qualifications} from "../components/qualifications";

import imgNeggin from '../images/team_neggin.jpg'
import imgYohan from '../images/team_yohan.jpg'
import imgKirollos from '../images/team_kirollos.png'
import imgMuneeza from '../images/team_muneeza.jpg'

export const dentists = [
  {
    "name": "Dr. Neggin Heidari-Robinson",
    "image": imgNeggin,
    "gdcNumber": 79766,
    qualifications: [
      {
        acronym: 'BDS',
        description: 'Bachelor of Dental Surgery'
      },
      {
        acronym: 'MFDS',
        description: 'Membership of the Faculty of Dental Surgeons'
      },
      {
        acronym: 'RCS',
        description: 'Royal College of Surgeons',
        extra: '(Eng) (2001)'
      }
    ],
    bio: [
      "Neggin grew up in Surrey and attended Croydon High School. She qualified from Kings College London in 2001 and subsequently worked as a senior house officer in Paediatric and Maxillofacial Surgery in King’s College and Kent and Canterbury Hospitals.",
      "Neggin has completed the Royal College of Surgeons’ course in implant dentistry and, for six years, taught students at Kings and Guys in oral surgery. She has also published work on the interpretation of X-rays in the British Journal of Oral and Maxillofacial Surgery. Neggin has experience in all aspects of general surgery, with a special interest in implants and endodontics (root canals). She has run her own practice, Pipers in Oxted, since 2012.",
      "For Neggin, the three most important things are: meticulous attention to quality, a gentle and caring environment for patients, and good value for money. Outside of work, she enjoys skiing and travel."
    ]
  },
  {
    "name": "Dr. Kirollos Henien",
    "image": imgKirollos,
    "gdcNumber": 264593,
    qualifications: [
      {
        acronym: 'MChD',
        description: 'Master of Dental Surgery'
      },
      {
        acronym: 'BChD',
        description: 'Bachelor of Dental Surgery'
      },
      {
        acronym: 'BSc',
        description: 'Bachelor of Science',
        extra: '(Leeds)'
      },
      {
        acronym: 'PGCert',
        description: 'Postgraduate Certificate'
      },
      {
        acronym: 'PGDip',
        description: 'Postgraduate Diploma'
      }
    ],
    bio: [
      "Kirollos grew up in Wallington and qualified from the University of Leeds in 2016. He has an interest in all aspects of dentistry, in particular, prevention, restorative dentistry and oral surgery. He especially enjoys working with children, helping them to learn about great dental care habits that will continue to help them throughout their lives. He is currently completing his MSc in primary dental care.",
      "Outside of work, Kirollos enjoys regularly going to the gym and hiking. He is also a big foodie and enjoys trying out different foods from all over the world."
    ]
  },
  {
    "name": "Dr. Muneeza Malik",
    "image": imgMuneeza,
    "gdcNumber": 112891,
    qualifications: [
      {
        acronym: 'BDS',
        description: 'Bachelor of Dental Surgery',
        extra: '{Birmingham)'
      },
      {
        acronym: 'MJDF',
        description: 'Membership of the Joint Dental Faculties'
      },
      {
        acronym: 'MSc (Endo)',
        description: 'Endodontics Master of Science'
      }
    ],
    bio: [
      "Muneeza qualified from the University of Birmingham in 2007 with a distinction in clinical dentistry. Since then she has been providing high quality dental treatment in London and Surrey and has also been involved in the training of newly qualified dentists.",
      "Muneeza has completed many postgraduate courses including training in Aesthetics dentistry, minimally invasive dentistry and Invisalign clear braces. Her special interest is in root canal treatments and she has completed a Masters degree in Endodontics at the Eastman dental institute (UCL, London) for which she was awarded a distinction.",
      "Muneeza offers the highest standard of care to her patients. Outside of dentistry her two young children keep her busy and, when she’s not running around after them, she loves to unwind with a book."
    ]
  }
]

const IndexPage = () => {

  return (
    <Layout pageTitle="Meet The Team">
      <SEO title="Meet The Team"/>
      <div>
        <h2 className="section-title">Meet the Team</h2>
        <span className="general-text">
          <p>At Pipers Dental Practice we are committed to providing our patients with the highest level of customer service.
    Our dentists are caring, highly qualified professionals with experience in all aspects of general, cosmetic and restorative dentistry and our staff are chosen
    for their ability, diligence and consideration.</p>
          <p>It has always been the philosophy of the practice to provide the highest possible standards of dental treatment within a relaxed and unhurried environment.</p>
        </span>
        <span>Our dentists are:</span>
      </div>
      <div className="new-row">
        {dentists.map((_dentist, idx) => {
          return (
            <div className="dentist" key={idx}>
              <header className="with-subtitle">
                <h3 className="info-title">{_dentist.name}</h3>{ _dentist.gdcNumber ? <span className="gdc-number">GDC Number {_dentist.gdcNumber}</span> : null }
                <Qualifications qualifications={_dentist.qualifications} />
              </header>
              <div>
                <img alt={_dentist.name} src={_dentist.image}/>
                <span className="general-text">{(_dentist.bio && _dentist.bio.map((_bit, idx) => (
                  <p key={idx}>{_bit}</p>
                ))) || null}</span>
              </div>
            </div>
          )
        })}


      </div>
    </Layout>
  )
}



export default IndexPage
