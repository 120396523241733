import React from 'react'

export const Qualifications = ({qualifications}) => {
  return (
    <span className="qualifications">
      {qualifications.map((_qualification, idx) => (
        <React.Fragment key={idx}>
          <abbr
            title={_qualification.description}>{_qualification.acronym}</abbr> {_qualification.extra}{idx < qualifications.length-1 ? ', ' : null}
        </React.Fragment>
      ))
      }
    </span>
  )
}